import React, { useEffect } from 'react';
import cn from 'classnames';

import { IBreak } from 'types/componentTypes';
import { useBreakTimer } from 'src/hooks/useBreakTimer';
import BreakPlaceHolder from 'components/Break/BreakPlaceHolder';
import { endBreak } from 'actions/statusToggleActions';
import useBindDispatch from 'src/hooks/useBindDispatch';
import BreakButton from 'components/Buttons/BreakButton';

import style from './styles.module.scss';

const Break: React.FC<IBreak> = ({
  breakEnds,
  isDelayed,
  initialBreakDuration,
}) => {
  const breakEndTime = breakEnds || Date.now();
  const handleStopBreak = useBindDispatch(endBreak);
  const { sec, min } = useBreakTimer(breakEndTime, isDelayed);

  const minutes = `${min < 10 ? `0${min}` : min}`;
  const seconds = `${sec < 10 ? `0${sec}` : sec}`;
  const isTimeOver = !min && !sec;

  return (
    <div className={style.breakContent}>
      <div className={style.breakTimerContent}>
        <div className={style.breakTimerContentTitle}>
          Break &#183;
        </div>
        <div className={cn(
          style.breakTimerContentDisplay,
          { [style.blinking]: isTimeOver },
        )}
        >
          {minutes}:{seconds}
        </div>
      </div>
      { !isDelayed && (
        <div
          className={style.endBreakButtonWrapper}
        >
          <BreakButton onClick={handleStopBreak} isDelayed={isDelayed} />
        </div>
      )}
      <div className={style.breakTimerContentPlaceholder}>
        <BreakPlaceHolder
          isDelayed={isDelayed}
          breakMinutes={initialBreakDuration}
        />
      </div>
    </div>
  );
};

export default Break;
