import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { PsychicStatusChannel } from 'extracted-chat-components/enums';
import cn from 'classnames';

import Callbacks from 'components/Callbacks/index';
import StandbyTitle from 'components/Titles/StandbyTitle';
import useBindDispatch from 'src/hooks/useBindDispatch';
import { selectStatusBreak, selectStatusToggle } from 'selectors/statusToggleSelectors';
import {
  selectCurrentUser,
  selectIsLiveChatActive,
  selectExtId,
  selectIsPsychicLoginOutsideOfSchedule,
  selectPsychicLoginOutsideOfScheduleMsg,
  selectPsychicLoginOutsideLastActivityId,
  selectCallBackQueueData,
} from 'selectors/selectors';
import {
  NotificationsText,
  PopUpNotificationType,
  ToggleTypes,
} from 'constants/enums';
import { setPsychicStatus as _setPsychicStatus } from 'actions/statusToggleActions';
import {
  setPopUpNotificationData as _setPopUpNotificationData,
  showPopUpNotification as _showPopUpNotification,
  setPsychicPhoneStatus as _setPsychicPhoneStatus,
  addPopUpNotification as _addPopUpNotification,
  setIsPsychicLoginOutsideOfSchedule as _setIsPsychicLoginOutsideOfSchedule,
  setIsPsychicLoginOutsideOfScheduleEditMode as _setIsPsychicLoginOutsideOfScheduleEditMode,
  setPsychicLoginOutsideLastActivityId as _setPsychicLoginOutsideLastActivityId,
  setPsychicLoginOutsideOfScheduleMsg as _setPsychicLoginOutsideOfScheduleMsg,
  setPsychicLoginOutsidePopupShow as _setPsychicLoginOutsidePopupShow,
} from 'actions/appActions';
import {
  checkIsPsychicLoginInsideOfSchedule,
} from 'src/utils/commonUtil';
import {
  setPsychicSchedules as _setPsychicSchedules,
  setPsychicCallbackQueue as _setPsychicCallbackQueue,
} from 'actions/chatActions';
import Break from 'components/Break';
import TogglePanel from 'components/Toggles/TogglePanel';
import Toggle from 'components/Toggles/Toggle';
import StatusBadge from 'components/Badges/StatusBadge';
import { useIsMobile } from 'src/hooks/useIsMobile';
import { getCurrentDate } from 'src/utils/dateHandler';
import { API } from 'src/utils/api';
import PriorityActionsWidget from 'components/PsychicNotifications/PriorityActionWidget';

import style from './Statusbar.module.scss';

const StatusBar = () => {
  const [image, setImage] = useState<string>('');

  const setPsychicStatus = useBindDispatch(_setPsychicStatus);
  const setPopUpNotificationData = useBindDispatch(_setPopUpNotificationData);
  const showPopUpNotification = useBindDispatch(_showPopUpNotification);
  const setPsychicPhoneStatus = useBindDispatch(_setPsychicPhoneStatus);

  const addPopUpNotification = useBindDispatch(_addPopUpNotification);
  const setIsPsychicLoginOutsideOfSchedule = useBindDispatch(_setIsPsychicLoginOutsideOfSchedule);
  const setPsychicLoginOutsideOfScheduleMsg = useBindDispatch(_setPsychicLoginOutsideOfScheduleMsg);
  const setPsychicCallbackQueue = useBindDispatch(_setPsychicCallbackQueue);
  const setPsychicSchedules = useBindDispatch(_setPsychicSchedules);
  const setPsychicLoginOutsideLastActivityId = useBindDispatch(
    _setPsychicLoginOutsideLastActivityId,
  );
  const setPsychicLoginOutsidePopupShow = useBindDispatch(_setPsychicLoginOutsidePopupShow);
  const setIsPsychicLoginOutsideOfScheduleEditMode = useBindDispatch(
    _setIsPsychicLoginOutsideOfScheduleEditMode,
  );

  const {
    phoneOnly,
    chatOnly,
    offlineMessages,
    isDMEnabled,
    isOnCall,
    isOnChat,
  } = useSelector(selectStatusToggle);
  const statusBarDisabled = useSelector(selectIsLiveChatActive);
  const statusBreak = useSelector(selectStatusBreak);
  const currentUser = useSelector(selectCurrentUser);
  const psychicLoginOutsideOfScheduleMsg = useSelector(selectPsychicLoginOutsideOfScheduleMsg);
  const psychicLoginOutsideLastActivityId = useSelector(selectPsychicLoginOutsideLastActivityId);
  const extId = useSelector(selectExtId);
  const isPsychicLoginOutsideOfSchedule = useSelector(selectIsPsychicLoginOutsideOfSchedule);
  const callBackQueueServerData = useSelector(selectCallBackQueueData);

  const { isMobile } = useIsMobile();

  const isPsychicAvailable = useMemo(() => phoneOnly || chatOnly, [chatOnly, phoneOnly]);

  useEffect(() => {
    const { images } = currentUser;

    if (images) {
      setImage(images[images.length - 1]);
    }
  }, [currentUser, setImage]);

  const showBreakPopup = useCallback(() => {
    const title = NotificationsText.BREAK;

    setPopUpNotificationData({
      title,
      notificationType: PopUpNotificationType.TAKE_A_BREAK,
    });
    showPopUpNotification(true);
  }, [setPopUpNotificationData, showPopUpNotification]);

  const onChangeChatStatus = useCallback((value) => {
    const isPhoneAndChat = value && phoneOnly;

    if (isPhoneAndChat) {
      return setPsychicStatus(isPhoneAndChat, PsychicStatusChannel.PHONE_CHAT);
    }

    return setPsychicStatus(value, PsychicStatusChannel.CHAT);
  }, [phoneOnly, setPsychicStatus]);

  const onChangePhoneStatus = useCallback(async (value) => {
    const isPhoneAndChat = value && chatOnly;

    if (!value) {
      if (!isOnCall) {
        setIsPsychicLoginOutsideOfSchedule(false);
        setIsPsychicLoginOutsideOfScheduleEditMode(false);
        setPsychicLoginOutsideLastActivityId(0);
      }

      const channel = isPhoneAndChat ? PsychicStatusChannel.PHONE_CHAT : PsychicStatusChannel.PHONE;
      setPsychicPhoneStatus(isPhoneAndChat || value, channel);
    } else if (isOnCall) {
      addPopUpNotification({
        isVisible: true,
        title: StandbyTitle,
        notificationType: PopUpNotificationType.STANDBY_PSYCHIC,
      });
    } else {
      setIsPsychicLoginOutsideOfSchedule(false);
      setPsychicSchedules({
        schedules: [],
        totalTimeInMinute: '',
      });
      setPsychicCallbackQueue([]);
      setPsychicLoginOutsideOfScheduleMsg('');
      const currentDate = getCurrentDate();
      let isPsychicLoginOutsideOfSchedule = false;
      const psychicSchedule = await API.Psychic.getSchedule(extId, currentDate);

      if (psychicSchedule?.isSuccess) {
        const { result, totalTimeInMinute } = psychicSchedule;
        const schedules = result ? result[currentDate] : '';
        setPsychicSchedules({ schedules, totalTimeInMinute });
        setIsPsychicLoginOutsideOfSchedule(
          !checkIsPsychicLoginInsideOfSchedule(schedules),
        );
        isPsychicLoginOutsideOfSchedule = !checkIsPsychicLoginInsideOfSchedule(schedules);
      } else {
        setIsPsychicLoginOutsideOfSchedule(true);
        isPsychicLoginOutsideOfSchedule = true;
      }

      const psychicCallbackqueue = await API.Psychic.getCallbackqueue(extId);
      const psychicCallbackQueue = psychicCallbackqueue.callBackQueueData
        .map((queueData) => ({ ...queueData, screened: 1 }));

      if (psychicCallbackqueue?.isSuccess) {
        setPsychicCallbackQueue(psychicCallbackQueue);
      }

      if (isPsychicLoginOutsideOfSchedule) {
        setPsychicLoginOutsideLastActivityId(0);
        setIsPsychicLoginOutsideOfScheduleEditMode(false);
        setPsychicLoginOutsidePopupShow(true);
        addPopUpNotification({
          isVisible: true,
          title: 'Logging Outside Schedule',
          notificationType: PopUpNotificationType.PSYCHIC_LOGIN_OUTSIDE_SCHEDULE,
          reason: isPhoneAndChat
            ? PsychicStatusChannel.PHONE_CHAT.toString()
            : PsychicStatusChannel.PHONE.toString(),
        });
      } else {
        if (isPhoneAndChat) {
          return setPsychicStatus(isPhoneAndChat, PsychicStatusChannel.PHONE_CHAT);
        }

        return setPsychicStatus(value, PsychicStatusChannel.PHONE);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatOnly, setPsychicStatus, isOnCall]);

  const onClickEditOutsideSchedule = async () => {
    setIsPsychicLoginOutsideOfScheduleEditMode(true);

    if (psychicLoginOutsideLastActivityId > 0) {
      setIsPsychicLoginOutsideOfSchedule(true);
    }

    const psychicCallbackqueue = await API.Psychic.getCallbackqueue(extId);
    const psychicCallbackQueue = psychicCallbackqueue.callBackQueueData
      .map((queueData) => ({ ...queueData, screened: 1 }));

    if (psychicCallbackqueue?.isSuccess) {
      setPsychicCallbackQueue(psychicCallbackQueue);
    }

    setPsychicLoginOutsidePopupShow(true);
    addPopUpNotification({
      isVisible: true,
      title: 'Logging Outside Schedule',
      notificationType: PopUpNotificationType.PSYCHIC_LOGIN_OUTSIDE_SCHEDULE,
      reason: chatOnly
        ? PsychicStatusChannel.PHONE_CHAT.toString()
        : PsychicStatusChannel.PHONE.toString(),
    });
  };

  const onChangeOfflineMessageStatus = useCallback((value) => {
    setPsychicStatus(value, PsychicStatusChannel.OFFLINE_MESSAGES);
  }, [setPsychicStatus]);

  return (
    <div className={style.wrapper}>
      {isMobile && (
        <div className={style.psychicInformation}>
          <img
            src={image}
            alt="Psychic"
          />
          <div>
            <h5 className={style.psychicInformationTitle}>
              Hi, <span>{currentUser.friendlyName}!</span>
            </h5>
            <StatusBadge />
          </div>
        </div>
      )}
      <PriorityActionsWidget />
      <Callbacks callBackQueueServerData={callBackQueueServerData} />

      <div className={style.statusContainer}>
        <div className={style.commandTogglesContainer}>
          {statusBreak.breakEnds ? (
            <Break
              initialBreakDuration={statusBreak.command}
              {...statusBreak}
            />
          ) : (
            <TogglePanel
              statusBarDisabled={statusBarDisabled}
              phoneStatus={phoneOnly}
              isOnCall={isOnCall}
              isOnChat={isOnChat}
              onChangePhoneStatus={onChangePhoneStatus}
              chatStatus={chatOnly}
              isPsychicAvailable={isPsychicAvailable}
              showBreakPopup={showBreakPopup}
              onChangeChatStatus={onChangeChatStatus}
              isPsychicLoginOutsideOfSchedule={isPsychicLoginOutsideOfSchedule}
              psychicLoginOutsideOfScheduleMsg={psychicLoginOutsideOfScheduleMsg}
              psychicLoginOutsideLastActivityId={psychicLoginOutsideLastActivityId}
              onClickEditOutsideSchedule={onClickEditOutsideSchedule}
            />
          )}
        </div>
        <p className={cn(style.omStatusPlaceholder, {
          [style.omStatusPlaceholderVisible]: !offlineMessages && isDMEnabled,
        })}
        >
          Customers are asking to Message you, but you have the feature <span>disabled. </span>
          <span className={style.omStatusPlaceholderEnable}>Please enable Offline Messages.</span>
        </p>
        <fieldset
          disabled={!isDMEnabled}
          className={cn(
            style.fieldSet,
            style.offlineMessageContainer,
            { [style.offlineMessagesToggleDisabled]: !offlineMessages },
          )}
        >
          <Toggle
            title="Offline Message"
            isChecked={offlineMessages}
            toggleType={ToggleTypes.ENABLED_DISABLED}
            onChange={onChangeOfflineMessageStatus}
          />
        </fieldset>
      </div>
    </div>

  );
};

export default StatusBar;
